import React, { FC } from 'react';

import LinksListItem from './LinksListItem';
import { ILinksList } from './models';
import './LinksList.scss';

const LinksList: FC<ILinksList> = ({ titleText, linksList }) => {
  const links = linksList.map((link) => (
    <LinksListItem key={link.titleText} {...link.linkURL[0]} />
  ));

  return (
    <div className="dt-links-list">
      {titleText && <h3 className="dt-article__sub-title">{titleText}</h3>}
      <ul className="dt-links-list__links-list">{links}</ul>
    </div>
  );
};

export default LinksList;
