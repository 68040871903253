import React, { FC } from 'react';

import IconCustom from 'components/common/IconCustom';
import { ILinksListItem } from './models';
import './LinksListItem.scss';

const LinksListItem: FC<ILinksListItem> = ({ name, url }) => (
  <li className="dt-links-list__item">
    <a href={url} className="dt-links-list__link">
      {name}
      <IconCustom icon="long_arrow_icon" />
    </a>
  </li>
);

export default LinksListItem;
